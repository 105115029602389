<template>
  <validation-observer v-slot="{ handleSubmit }" ref="simpleRules">
    <b-form @submit.prevent="handleSubmit(updateProfile)" method="post">
      <b-card>
        <!-- media -->
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                :src="imageSrc"
                v-model="imageSrc"
                height="80"
              />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="image"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
            >
              Reset
            </b-button>
            <!--/ reset -->
            <b-card-text
              >Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text
            >
          </b-media-body>
        </b-media>
        <!--/ media -->

        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col sm="6">
              <b-form-group label="Name" label-for="Name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.name"
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Phone Number">
                <validation-provider
                  #default="{ errors }"
                  name="phone no"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.phone_no"
                    name="phone_no"
                    type="number"
                    placeholder="Enter Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.email"
                    name="email"
                    type="text"
                    placeholder="Enter Email"
                      disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Address" label-for="address">
                <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.address"
                    name="address"
                    placeholder="Address"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="State" label-for="state">
                <v-select
                  v-model="user.selectedState"
                  :options="stateList"
                  label="state_name"
                  @input="getDistrict"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="District" label-for="district">
                <v-select
                  v-model="user.selectedDistrict"
                  :options="districtList"
                  label="district_name"
                  @input="getCity"
                />
                <!--   @input="getCity" -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="City" label-for="city">
                <v-select
                  v-model="user.selectedCity"
                  :options="cityList"
                  label="city_name"
                  @input="changeCity"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.enter="handleSubmit(updateProfile)"
              >
                Save changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BFormSelect,
  BFormDatepicker,
  BFormRadio,
  BFormFile,
  BAlert,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import axiosIns from "@/libs/axios";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormRadio,
    BFormSelect,
    BFormDatepicker,
    flatPickr,
    Logo,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userDetails: null,
      image: null,
      imageSrc: null,
      url: null,
      stateList: [],
      districtList: [],
      cityList: [],

      user: {
        name: null,
        phone_no: null,
        address: null,
        email:null,
        selectedState: null,
        selectedDistrict: null,
        selectedCity: null,
      },
    };
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  methods: {
    getDistrictLabel(option) {
      return option.district_name;
    },
    searches(event) {},

    userInformation() {
      axiosIns
        .get("web/me")
        .then((response) => {
          this.user = response.data.user;
          this.imageSrc = response.data.user.user_image.url;
          this.user.selectedState = response.data.user.state;
          this.user.selectedDistrict = response.data.user.district;
          this.user.selectedCity = response.data.user.city;
        })
        .catch((error) => {});
    },
    getState() {
      axiosIns
        .get(`web/get-state-list`)
        .then((response) => {
          this.stateList = response.data.state_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getDistrict(event) {
      axiosIns
        .get(`web/get-district-list?state_id=${event.id}`)
        .then((response) => {
          this.districtList = response.data.districts_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getCity(event) {
      axiosIns
        .get(`web/get-city-list?district_id=${event.id}`)
        .then((response) => {
          this.cityList = response.data.city_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    updateProfile() {
      // let formData = this.objectToFormData(this.user);
      var data = new FormData();
      data.append("name", this.user.name);
      data.append("phone_no", this.user.phone_no);
      data.append("address", this.user.address);
      data.append("state", this.user.selectedState.id);
      data.append("district", this.user.selectedDistrict.id);
      data.append("city", this.user.selectedCity.id);
      data.append("user_image", this.image);
      axiosIns
        .post(`web/auth/updateProfile`, data)
        .then((response) => {
          const message = response.data.message;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "success",
              text: message,
            },
          });
          this.userInformation();
        })
        .catch((error) => {
          const data = error.response.data.message;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
        });
    },

    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
  },
  created() {
    this.getState();

    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.userInformation();
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
